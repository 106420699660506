import './loading-indicator-spinner.component.scss';

import { Translation } from '../../translation/translation.component';

interface Props {
  isVisible?: boolean;
  headerText?: ResourceKey;
  mainText?: ResourceKey | string;
}


export const LoadingIndicatorSpinner = ({isVisible, headerText, mainText} : Props) => {

  if (!isVisible) {
    return null;
  }

  return (
    <div className="spinner-container centered-spinner" data-testid="spinner-container">
      {
        headerText && <div className="spinner-header-text h3"><Translation resource={headerText}/></div>
      }
      <div className="spinner-fade-in">
        <div className="chr-ring">
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
      </div>
      {
        mainText && <div className="spinner-sub-text h2"><Translation resource={mainText}/></div>
      }
    </div>
  );
};
